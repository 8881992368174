










































import { Vue, Component } from 'vue-property-decorator';
import LoadingHandler from '@/utils/loading-handler';
import { createMaxRule, requiredRule, typeEmailRule, typeTelno } from '@/utils/validation-rules';
import { ValidateWithScroll } from '@/utils/validation-handler';

import InquiryRepository from '@/repositories/inquiry-repository';
import {dispatchInitialized} from "@/libs/cypress";

@Component
export default class extends Vue {
  private formData:any = { name: "", nameKana: "", email: "", telno: "", text: "" };

  protected mounted() {
    dispatchInitialized();
  }

  private get rules(){
    return {
      name: [requiredRule, createMaxRule(255, 'char')],
      nameKana: [requiredRule, createMaxRule(255, 'char')],
      email: [requiredRule, typeEmailRule, createMaxRule(255, 'char')],
      telno: [requiredRule, typeTelno, createMaxRule(24, 'char')],
      text: [requiredRule, createMaxRule(1024, 'char')],
    }
  }

  private async submit() {
    if (!(await ValidateWithScroll(this.$refs.form as any))) return;

    LoadingHandler(async () => {
      return await InquiryRepository.create(this.formData);
    }).then(() => {
      this.$router.push({name: 'inquiry.complete'});
    });

  }
}
