import VueScrollTo from 'vue-scrollto';
import {ElForm} from 'element-ui/types/form';

export function ValidateWithScroll($form:ElForm, offset = 0): Promise<boolean> {
  return new Promise((resolve, reject) => {
    $form.validate(function(res, failedObjs) {
      if (res) return resolve(true);

      console.log(failedObjs);
      const firstErrorProp = Object.keys(failedObjs)[0];
      const fields = ($form as any).fields;
      const firstErrorField = fields.filter(f => f.prop === firstErrorProp)[0];

      VueScrollTo.scrollTo(firstErrorField.$el, {
        offset: -(firstErrorField.$el.clientHeight + offset)
      });

      return resolve(false);
    });
  });
}
