


























import { Vue, Component} from 'vue-property-decorator';
import {dispatchInitialized} from "@/libs/cypress";

@Component
export default class extends Vue {
  protected mounted() {
    dispatchInitialized();
  }
}
